@use 'mixins';

$-provider-logo-width: 3em;

.ArticlePromoItem {
    :global(.betting-disabled) & {
        display: none;
    }

    display: flex;
    padding: var(--standardPadding);
    line-height: 1.25em;

    :global(.tag) {
        margin-right: var(--smallPadding);
        padding: 0.17em 0.37em 0.25em;
    }

    .betting-provider-logo {
        display: flex;
        height: $-provider-logo-width;
        min-width: $-provider-logo-width;
        margin-right: var(--tinyPadding);
        align-items: center;
        justify-content: center;
        background: rgb(var(--bettingColor));
        border-radius: 50%;

        img {
            display: block;
            width: 2.125em;
            height: auto;
            margin: 0 auto;
        }
    }

    .betting-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: var(--standardPadding);

        .logo-betting svg {
            width: auto;
            height: 20px;
            aspect-ratio: 96/20;
        }
    }

    // parent class of *-item
    .article-promo-item {
        width: 100%;

        .inner-container {
            display: flex;
            justify-content: space-between;

            h4 {
                margin: auto var(--standardPadding) auto auto;
                color: rgb(var(--textColor));

                [data-theme='vi'] & {
                    color: rgb(var(--bettingColor));
                }
            }

            .logo-wrapper {
                display: flex;
                align-items: center;
                flex-direction: row;
            }

            img {
                display: block;
                width: auto;
                height: 1em;
                margin-right: 0;
            }

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;

                a {
                    margin-right: var(--smallPadding);
                    text-decoration: none;
                }
            }
        }
    }

    &.promoElementBelowVideoCarousel-item,
    &.promoElementBelowArticle-item {
        [data-theme='vi'] & {
            margin-bottom: var(--standardPadding);
        }

        .inner-container {
            [data-theme='vi'] & {
                justify-content: initial;
                border-bottom: 1px solid rgb(var(--sectionBorder));
                padding-bottom: var(--smallPadding);
                margin-bottom: var(--smallPadding);
            }

            img {
                [data-theme='vi'] & {
                    width: 60px;
                    height: auto;
                }
            }

            .content {
                [data-theme='vi'] & {
                    align-items: flex-start;
                    flex-direction: column;
                    margin-left: var(--largePadding);
                }

                h4 {
                    margin-bottom: var(--tinyPadding);
                }
            }
        }
    }

    &.comments-item {
        padding: 0;

        .inner-container {
            padding: var(--standardPadding);
            background: rgb(var(--lightSectionBack));
            border-radius: var(--standardRadius);

            [data-theme='vi'] & {
                flex-direction: row;
            }

            .content {
                justify-content: space-between;

                h4 {
                    width: 100%;
                    margin-left: 0;
                }

                a {
                    color: rgb(var(--textColor));
                    text-decoration: underline;
                }
            }
        }

        :global(.disclaimer) {
            padding: var(--standardPadding);
        }
    }

    &.promotedArticle-item {
        background-color: rgb(var(--lightSectionBack));
        border-left: var(--standardRadius) solid rgb(var(--backgroundColor));

        .inner-container {
            flex-direction: row-reverse;
            justify-content: left;
            align-items: center;

            img {
                height: 3.3em;
            }

            a {
                color: rgb(var(--textColor));
            }

            > a {
                margin-left: auto;
            }

            .content {
                flex-direction: column;
                align-items: flex-start;
                margin-left: var(--largePadding);

                h4 {
                    margin-top: var(--tinyPadding);
                }
            }
        }

        /* stylelint-disable-next-line selector-class-pattern */
        :global(.right-icon) {
            color: rgb(var(--backgroundColor));
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
        }

        :global(.tag) {
            letter-spacing: 0.2px;
            background-color: rgb(var(--invertedBackground));
        }
    }
}

@include mixins.responsive(m, 'below') {
    .ArticlePromoItem {
        padding: 0 var(--standardPadding);

        &.promoElementBelowArticle-item {
            .inner-container {
                .logo-wrapper {
                    align-items: flex-start;
                    margin-top: 0.25em;
                }

                .content {
                    [data-theme='vi'] & {
                        margin-left: var(--standardPadding);
                    }
                }
            }
        }

        &.promotedArticle-item {
            margin: 0 var(--standardPadding);

            .inner-container {
                padding: var(--standardPadding) 0;

                h4 {
                    display: inline;
                }

                .content {
                    display: block;
                    margin-left: var(--standardPadding);

                    a {
                        margin-right: 0;
                    }
                }
            }

            /* stylelint-disable-next-line selector-class-pattern */
            :global(.right-icon) {
                display: none;
            }
            /* stylelint-disable-next-line selector-class-pattern */
            :global(.tag) {
                letter-spacing: 0.4px;
            }
        }
    }
}
