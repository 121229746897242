@use 'mixins';
@use 'layout/responsive';
@use 'layout/basics';

$-small-thumb-width: 220px;
$-small-thumb-height: 60px;

.FeaturedCarrousel {
    /* stylelint-disable-next-line scss/at-mixin-pattern */
    @mixin adjustableFontSize($size) {
        font-size: $size;

        &.size-s {
            font-size: 0.9 * $size;
        }

        &.size-l {
            font-size: 1.1 * $size;
        }

        &.size-xl {
            font-size: 1.25 * $size;
        }

        &.size-xxl {
            font-size: 1.5 * $size;
        }

        &.size-xxxl {
            font-size: 2 * $size;
        }
    }

    $-height: 28.75vw;
    $-footer-image-height: 14.5vw;
    $-big-title-size: 3vw;
    $-small-title-size: 1.75vw;
    $-small-text-size: 1.2vw;

    height: $-height;

    @extend %smallScalablePaddingBottom;

    a,
    article,
    figure {
        display: block;
        width: 100%;
    }

    a,
    article {
        height: 100%;
        display: flex;
        text-decoration: none;
    }

    article {
        position: relative;
        border-radius: var(--standardRadius);
        overflow: hidden;
    }

    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;

        &::before {
            height: 40%;
            bottom: 10%;
        }

        &::after {
            height: 100%;
            bottom: 0;
            background: linear-gradient(180deg, transparent 50%, rgb(var(--invertedBackground)) 90%);
            pointer-events: none;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;

            [data-theme='br'] & {
                display: none;
            }
        }
    }

    h2 {
        @include adjustableFontSize($-small-title-size);
    }

    .double-gradient {
        &::after {
            background: linear-gradient(180deg, transparent 50%, rgb(var(--invertedBackground)) 90%),
                linear-gradient(90deg, transparent 50%, rgb(var(--invertedBackground)) 90%);

            [data-theme='br'] & {
                display: block;
            }
        }

        [data-theme='br'] & {
            clip-path: none !important;
        }
    }

    .upper-item {
        margin-bottom: var(--largePadding);
    }

    .responsive-content-text {
        @include adjustableFontSize($-small-text-size);
    }

    .title-background {
        display: none;
        position: absolute;
        z-index: -1;
        width: calc(100% - var(--tinyPadding) * 2);

        &::before {
            content: attr(data-content);
            color: transparent;
            background-color: rgb(var(--invertedBackground));
            outline: 0.15em solid rgb(var(--invertedBackground));
        }

        [data-theme='br'] & {
            display: block;
        }
    }

    .info {
        z-index: 3;

        h2 {
            margin: var(--smallPadding) 0;
            overflow-wrap: break-word;
            white-space: break-spaces;

            [data-theme='br'] & {
                position: relative;
                padding: var(--tinyPadding);
                margin: var(--tinyPadding) 0;
                line-height: 1.143em;
            }
        }
    }

    .expanded-info {
        bottom: 0;
        width: 90%;
        position: absolute;
        padding: var(--largePadding);
        color: rgb(var(--invertedTextColor));
    }

    .expanded-when-minified-title {
        width: 60%;
    }

    .footer-info,
    .minified-info {
        position: relative;

        .title-background {
            display: none !important;
        }

        h2 {
            font-size: 1em;
        }
    }

    .footer-info {
        color: rgb(var(--invertedBackground));
    }

    .minified-info {
        color: rgb(var(--invertedTextColor));
    }

    .responsive-only {
        display: none;
    }

    %container {
        position: relative;
        width: 100%;
        height: inherit;
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin-bottom: 0;
        list-style-type: none;
        overflow-x: hidden;
    }

    .es-container,
    .tm-container {
        @extend %container;

        flex-direction: column;
    }

    .ef-container,
    .ms-container {
        @extend %container;

        flex-direction: row;
    }

    .xsmall {
        width: calc(35% - var(--standardPadding));
        height: calc(18% - var(--standardPadding));
        padding-left: var(--standardPadding);

        .info {
            padding-left: var(--standardPadding);
        }
    }

    .minified-side-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 1px;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 65%;
        border-bottom: 1px solid rgba(var(--invertedTextColor), 0.1);
        border-left: 1px solid rgba(var(--invertedTextColor), 0.1);

        .xsmall {
            padding: var(--standardPadding);
            border-radius: 1px;
            border-bottom: 1px solid rgba(var(--invertedTextColor), 0.1);

            &:first-child {
                border-top: 1px solid rgba(var(--invertedTextColor), 0.1);
            }
        }
    }

    .minified-article-image {
        position: relative;
        max-width: calc(5 * var(--largePadding) - var(--standardPadding));
        width: $-small-thumb-width;
        height: $-small-thumb-height;
        box-sizing: border-box;

        img {
            width: 100%;
            height: auto;
        }
    }

    .minified-article-content {
        display: flex;
        align-items: center;
    }

    .footer-article-content {
        flex: 1;
    }

    .footer-article-image {
        position: relative;
        height: $-footer-image-height;
        width: 100%;
        overflow: hidden;
        border-radius: calc(2 * var(--standardRadius));
    }

    .full,
    .xxlarge,
    .xlarge {
        [data-theme='br'] & {
            figure {
                clip-path: polygon(0 0, 100% 0, 100% 85%, -2% 100%);
            }
        }
    }

    .full {
        width: 100%;
        height: 100%;
    }

    .xxlarge {
        width: calc(100% / 3 * 2);
        height: 100%;
        padding-right: var(--standardPadding);
    }

    .xlarge {
        width: 100%;
        height: 55%;
        margin-bottom: var(--standardPadding);
    }

    .large {
        width: calc(50% - var(--largePadding));
        height: 100%;
        padding-right: var(--largePadding);
    }

    .medium {
        width: calc(100% / 3 - var(--largePadding));
        height: calc(50% - var(--standardPadding));
        padding-left: var(--standardPadding);

        .info {
            padding: var(--standardPadding);
        }
    }

    .small {
        width: calc(100% / 3 - 2 * var(--standardPadding));
        height: calc(44% - var(--largePadding));
        padding: 0 var(--standardPadding);

        &.first {
            padding-left: 0;
        }

        .comments {
            padding-top: 0;

            [data-theme='gp'] & {
                padding-top: var(--smallPadding);
                margin-top: var(--smallPadding);
            }
        }
    }

    .comments {
        @extend %caption-small;

        width: fit-content;
        margin-top: var(--standardPadding);
        line-height: 1.5em;
        text-align: center;
        color: rgba(var(--invertedTextColor), 0.6);

        :global(.Icon) {
            font-size: 1.25rem;
            color: rgba(var(--invertedTextColor), 0.6);
            margin-right: var(--tinyPadding);
            vertical-align: bottom;
        }

        [data-theme='gp'] & {
            padding-top: var(--standardPadding);
            margin-top: var(--standardPadding);
            border-top: 3px solid rgb(var(--mainColor));
        }
    }

    .footer-comments {
        color: rgba(var(--textColor), 0.6);

        :global(.Icon) {
            color: rgba(var(--textColor), 0.6);
        }
    }

    .action-section-container {
        z-index: 4;
        top: 5px;
        right: 5px;
        position: absolute;
        display: flex;
        cursor: pointer;
        background-color: white;
        color: lightgray;
        border-radius: 10px;
        border: 1px solid lightgray;
    }

    .default-button-icon {
        padding: 5px;
        font-size: 1.5em;
    }

    .disabled-button-icon {
        :global(.Icon) {
            color: lightgray;
        }
    }

    .overlay {
        position: absolute; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        inset: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
        z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    }

    .edit-input {
        background: transparent;
        border: none;
        color: rgb(var(--invertedTextColor));
        outline: 0 solid transparent;
    }

    .slider-bar {
        display: none;
    }

    @include mixins.responsive(m, above) {
        .large h2 {
            @include adjustableFontSize($-big-title-size);
        }
    }

    @include mixins.responsive(xxxl, above) {
        height: mixins.convertVwToFixed($-height, xxxl);

        h2 {
            $-size: mixins.convertVwToFixed($-small-title-size, xxxl);

            @include adjustableFontSize($-size);
        }

        .large h2 {
            $-size: mixins.convertVwToFixed($-big-title-size, xxxl);

            @include adjustableFontSize($-size);
        }

        .footer-article-image {
            height: mixins.convertVwToFixed($-footer-image-height, xxxl);
        }
    }

    @include mixins.responsive(m, below) {
        height: auto;

        .responsive-only {
            display: block;
        }

        .ms-container,
        .es-container {
            @include mixins.horizontalScrollSnapOneElement;

            flex-direction: column;
            height: 60vw;
        }

        .ms-container {
            hr {
                display: none;
            }
        }

        .ef-container,
        .tm-container {
            flex-direction: row;
            height: calc(4 * $-height);
        }

        .ef-container {
            overflow-y: hidden;
            justify-content: space-between;
        }

        h2 {
            $-size: $-big-title-size * 2;

            @include adjustableFontSize($-size);
        }

        .expanded-when-minified-title {
            width: 90%;
        }

        .responsive-article {
            padding: 0;
            margin: 0;

            .info {
                padding: var(--standardPadding) var(--standardPadding) var(--largePadding);
            }
        }

        .large {
            width: 100%;
            height: 50%;
        }

        .xlarge {
            width: 100%;
            height: 50%;
            margin-bottom: var(--smallPadding);
        }

        .comments {
            padding-bottom: var(--smallPadding);
        }

        .xsmall,
        .medium,
        .xxlarge {
            width: 100%;
            height: 100%;
        }

        .small {
            width: 49%;
            height: 50%;
        }

        .double-gradient {
            &::after {
                background: linear-gradient(180deg, transparent 50%, rgb(var(--invertedBackground)) 90%);
            }
        }

        .responsive-content-text {
            @include adjustableFontSize($-big-title-size);
        }

        .minified-side-container {
            display: none;
        }

        .slider-bar {
            display: block;
            position: relative;
            padding: 0;
            margin-left: var(--standardPadding);
            margin-top: calc((var(--largePadding) + var(--tinyPadding)) * -1);

            li {
                display: inline-block;
                width: 1.5em;
                height: 1em;
                margin-right: 0.25em;
                transition: background-color 0.3s ease;
            }
        }

        .indicator {
            display: inline-block;
            height: 0.1875em;
            width: 100%;
            line-height: 1em;
            vertical-align: middle;
            background-color: rgb(var(--lightSectionBack));

            &:hover {
                background-color: rgb(var(--sectionBack));
            }

            &.active {
                background-color: rgb(var(--mainColor));
            }
        }
    }
}
