/* stylelint-disable selector-class-pattern */
@use 'mixins';

$-container-padding: var(--standardPadding);
$-border-width: var(--standardBorderWidth);

// Mobile sizes
$-row-width: calc(100% - $-container-padding * 2);
$-item-width-third: calc($-row-width / 3);
$-item-width-quarter: calc($-row-width / 4);

// Max desktop sizes
$-row-width-max: calc(60% - $-container-padding * 2);
$-item-width-max-third: calc($-row-width-max / 3);
$-item-width-max-quarter: calc($-row-width-max / 4);

%-flex-default {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

%-one-third {
    @extend %-flex-default;

    width: $-item-width-third;
}

%-flex-row {
    @extend %-flex-default;

    flex-direction: row;
    width: $-row-width;
}

.MatchBlock {
    display: block;
    position: relative;
    overflow: hidden;
    color: rgb(var(--invertedTextColor));
    border-radius: var(--standardRadius);

    .row-item {
        @extend %-one-third;

        max-width: $-item-width-third;
        color: rgb(var(--invertedTextColor));
    }

    .betting-logo {
        img,
        svg {
            width: auto;
            height: 1em;
        }
    }

    .betting-odds {
        @extend %-flex-row;
    }

    .match-info {
        .time {
            @extend %heading-small;

            font-size: 1.25em;
        }

        .date {
            @extend %caption-medium;

            font-weight: initial;
            white-space: nowrap;
            color: rgb(var(--lighterTextColor));
        }
    }

    &.module-articleMatches {
        @extend %section-shadow;

        background: rgb(var(--lightSectionBack));

        &::before {
            display: flex;
            position: absolute;
            width: $-border-width;
            background: rgb(var(--bettingColor));
            z-index: 0;
            content: '';
            inset: 0;
        }

        a h4 {
            color: initial;
        }

        .betting-logo {
            flex: 1;
            max-width: $-item-width-quarter;

            img,
            svg {
                height: 1.5em;
            }
        }

        .betting-odds {
            flex: 1;
        }

        .match-info {
            .time {
                font-size: 1.5em;
                color: initial;
            }
        }

        .club-row,
        .odds-row {
            background: initial;
        }

        .club-row {
            padding-bottom: var(--smallPadding);

            > :nth-child(1),
            > :nth-child(3) {
                justify-content: center;
            }

            > :nth-child(1) {
                flex-direction: row-reverse;

                h4 {
                    margin-right: var(--tinyPadding);
                }
            }

            > :nth-child(3) {
                flex-direction: row;

                h4 {
                    margin-left: var(--tinyPadding);
                }
            }
        }

        .odds-row {
            padding: var(--smallPadding) $-container-padding $-container-padding $-container-padding;
        }

        .disclaimer-row {
            position: relative;
            background: rgb(var(--lightSectionBack));
            z-index: 1;
            border-top: 1px solid rgb(var(--sectionBorder));
        }
    }

    &.module-oddsDossierHome {
        background: rgb(var(--invertedBackground));

        .betting-logo {
            margin-top: var(--standardPadding);
        }

        .match-info {
            .time {
                color: rgb(var(--invertedTextColor));
            }

            .date {
                color: rgb(var(--lighterTextColor));
            }
        }

        .club-row {
            margin-bottom: var(--standardPadding);

            > :nth-child(1),
            > :nth-child(3) {
                h4 {
                    margin-top: var(--tinyPadding);
                }
            }
        }

        .disclaimer-row {
            [class^='BettingDisclaimer_Disclaimer'] {
                flex-direction: column-reverse;

                [class^='BettingDisclaimer_settings'] {
                    padding-right: initial;
                }
            }
        }
    }

    .club-row,
    .odds-row,
    .disclaimer-row {
        @extend %-flex-row;
    }

    .club-row {
        padding-top: var(--largePadding);
        padding-right: $-container-padding;
        padding-left: $-container-padding;
    }

    .team-row {
        margin: 0 auto;
        padding: var(--tinyPadding) $-container-padding $-container-padding $-container-padding;
    }

    .odds-row {
        flex: 0;
        padding: var(--smallPadding) $-container-padding;
        background: rgb(var(--lightestInvertedBackground));
    }

    .disclaimer-row {
        width: 100%;

        [class^='BettingDisclaimer_Disclaimer'] {
            flex: 1;
            padding: $-container-padding;
        }

        :global(.Logo),
        :global(.Icon) {
            height: 20px;
        }
    }

    @include mixins.responsive(m, above) {
        &.module-articleMatches {
            .betting-logo {
                flex: 1;
                max-width: 25%;
            }

            .betting-odds {
                max-width: 75%;
            }

            .club-row {
                > :nth-child(1),
                > :nth-child(3) {
                    justify-content: flex-end;
                }
            }

            .club-row,
            .odds-row {
                display: flex;
                flex: 1;
                max-width: $-row-width-max;
                margin: auto;
            }
        }
    }

    @include mixins.responsive(l, below) {
        &.module-articleMatches {
            .row-item {
                justify-content: unset;
                max-width: initial;
            }
        }
    }
}
